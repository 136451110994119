import React, { useState } from 'react';
import { useAppointmentData } from 'hooks/useAppointmentData';
import { useAppointmentParamsFromQuery } from 'hooks/useAppointmentParamsFromQuery';
import { useXrayData } from 'hooks/useXrayData';
import { containerStyles, getActiveExamTypesFromAppointment } from 'helpers/helpers';
import { ExamNameNormalizer, ExamTypes, ViewTypes, Xray } from 'models/XrayModels';
import { useAuth } from 'hooks/useAuth';
import MDBox from 'components/atoms/MDBox/MDBox';
import { useXrayMutations } from 'hooks/useXrayMutations';
import { sendXraysForAnalysis } from 'services/mlService';
import { Card, Container, Grid, Modal, Typography } from '@mui/material';
import MDAppBar from 'components/organisms/MDAppBar/MDAppBar';
import { Breakpoints, MUIColors } from 'models/StyleModels';
import { useBreakpoints } from 'hooks/useBreakpoints';
import MDTypography from 'components/atoms/MDTypography/MDTypography';
import MDButton from 'components/atoms/MDButton/MDButton';
import colors from 'assets/theme/base/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faCircleNotch, faDownload, faPen, faX } from '@fortawesome/free-solid-svg-icons';
import { RouteKeys } from 'models/RouteModels';
import { usePatientData } from 'hooks/usePatientData';
import { routesMap } from 'data/routes';
import { useNavigate } from 'react-router-dom';
import { reqViewsByExam } from 'data/dataConstants';
import { Appointment } from 'models/AppointmentModels';
import { Patient } from 'models/PatientModels';
import Spinner from 'components/molecules/Spinner/Spinner';
import { User } from 'models/UserModels';
import XrayThumb from './XrayThumb';
import TriageFlowLayout from 'components/templates/TriageFlowLayout/TriageFlowLayout';
import { useDropzone } from 'react-dropzone';
import EditJointsForm from './EditJointsForm';

interface IMatchXraysPageProps {
    user: User;
    accessToken: string;
    appointment: Appointment;
    singlePatient: Patient;
    allAppointmentXrays: Xray[];
}

const MatchXraysContent: React.FC<IMatchXraysPageProps> = ({
    user,
    accessToken,
    appointment,
    singlePatient,
    allAppointmentXrays
}) => {
    const [draggedItem, setDraggedItem] = useState<string | null>(null);
    const [draggedOverUnassigned, setDraggedOverUnassigned] = useState<boolean>(false);
    const [showConfirmMissingXraysModal, setShowConfirmMissingXraysModal] = useState(false);
    const [showEditJointsModal, setShowEditJointsModal] = useState(false);

    const navigate = useNavigate();
    const { breakpointBreached: mdBreakpointBreached } = useBreakpoints({ breakpoint: Breakpoints.MEDIUM });
    const { breakpointBreached: lgBreakpointBreached } = useBreakpoints({ breakpoint: Breakpoints.LARGE });

    const { updateXray, updateXrayLoading, createBatchXrayLoading, createBatchXrays, autoAssignXrays } = useXrayMutations({ appointment });

    const {
        getRootProps,
    } = useDropzone({
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/dcm': ['.dcm', '.dicom'],
        },
        onDropAccepted: async (acceptedFiles) => {
            await createBatchXrays({
                files: acceptedFiles,
                patientId: singlePatient.patient_id
            });

            autoAssignXrays({
                appointmentId: appointment.appointment_id,
                appointmentDate: appointment.appointment_date
            })
        }
    });

    const handleDragStart = (e: React.DragEvent<HTMLDivElement>, id: string) => {
        setDraggedItem(id);
        e.dataTransfer.effectAllowed = 'move';
    };

    const handleDragOverUnassigned = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setDraggedOverUnassigned(true)
        e.dataTransfer.dropEffect = 'move';
    };

    const handleDragLeaveUnassigned = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setDraggedOverUnassigned(false)
        e.dataTransfer.dropEffect = 'move';
    };

    const handleDrop = async (e: React.DragEvent<HTMLDivElement>, viewType: ViewTypes | null, examType: ExamTypes | undefined | null) => {
        e.preventDefault();
        if (draggedItem === null) return;

        const xray = allAppointmentXrays?.find((xray) => xray.xray_id === draggedItem)!

        setDraggedOverUnassigned(false)

        await updateXray({
            xray,
            newValues: {
                client_provided_view_type: viewType,
                joint: examType,
            }
        })

        setDraggedItem(null);
    };

    const handleSubmission = async () => {
        let xrays: Xray[] = []
        examItems.forEach((examItem) => {
            xrays = [...xrays, ...(Object.values(examItem.matchedXrays).map(xray => xray))]
        })

        const xraysForAnalysis = xrays
            //AVT TODO: move this filter to the backend
            .filter(xray => xray.view_type_results.viewType !== 'lateral_knee')
            .reduce((prev, curr) => {
                const key = curr.client_provided_view_type
                if (!key) {
                    return prev
                }

                return {
                    ...prev,
                    [key]: curr
                }
            }, {})

        sendXraysForAnalysis(xraysForAnalysis, accessToken, user)
    }

    const activeExamTypes = getActiveExamTypesFromAppointment(appointment);
    const examItems = activeExamTypes.map((examType) => {
        const requiredViews = reqViewsByExam[examType]

        const matchedXrays: { [key: string]: any } = (allAppointmentXrays || []).filter((xray) => {
            if (xray.client_provided_view_type) {
                return true
            }

            return false
        }).reduce((prev, curr) => {

            return { ...prev, [curr.client_provided_view_type]: curr }

        }, {})

        return {
            id: examType,
            matchedXrays,
            requiredViews
        }
    })

    const unmatchedXrays = allAppointmentXrays?.filter((xray => {
        if (!xray.client_provided_view_type) {
            return true
        }

        return false
    }))

    return (
        <Container>
            <TriageFlowLayout title="Upload X-rays" singlePatient={singlePatient}>
                <MDBox
                    display="grid"
                    gridTemplateColumns={lgBreakpointBreached ? "1fr" : "1fr 5fr"}
                    alignItems="center"
                    style={{ position: 'relative' }}
                    padding="2rem 0"
                    gap="2rem">

                    <MDButton
                        onClick={() => navigate(routesMap[RouteKeys.TRIAGE_LIST].route)}
                        variant="outlined" style={{
                            color: colors.secondary.main,
                            borderColor: colors.secondary.main,
                            whiteSpace: 'nowrap',
                            width: 'fit-content',
                        }}>
                        <FontAwesomeIcon icon={faChevronLeft} color={colors.secondary.main} style={{ marginRight: '.5rem' }} />
                        Triage List
                    </MDButton>

                    <span style={{ textAlign: 'center', display: 'block', color: colors.secondary.main, fontWeight: 700, }}>
                        Drag X-rays from the ‘Upload’ box to the ‘Confirmation’ box or vice versa to switch out X-rays.
                    </span>
                </MDBox>

                <MDBox pt={2}>
                    <MDBox display="grid" gridTemplateColumns={lgBreakpointBreached ? "1fr" : "1fr 1fr"} gap="2rem">

                        <MDBox display="grid" gridTemplateRows="3rem 50vh" gap="1rem" >
                            <MDBox display="flex" justifyContent="space-between" alignItems='flex-end' height="3rem">
                                <Typography color={MUIColors.SECONDARY} fontWeight="bold">
                                    Unassigned X-Rays
                                </Typography>
                                <MDButton
                                    {...getRootProps()}
                                    color={MUIColors.SECONDARY}
                                    variant="contained"
                                >
                                    <FontAwesomeIcon
                                        spin={createBatchXrayLoading}
                                        icon={createBatchXrayLoading ? faCircleNotch : faDownload}
                                        color={colors.white.main}
                                        style={{ marginRight: '.5rem' }} />
                                    {createBatchXrayLoading ? 'Uploading...' : 'Upload More'}
                                </MDButton>
                            </MDBox>

                            <MDBox
                                display="grid"
                                border="3px dashed"
                                borderColor={draggedOverUnassigned ? colors.success.main : colors.secondary.main}
                                borderRadius="10px"
                                padding='2rem'
                                alignItems="flex-start"
                                onDragOver={(e) => handleDragOverUnassigned(e)}
                                onDragLeave={(e) => handleDragLeaveUnassigned(e)}
                                onDrop={(e) => handleDrop(e, null, null)}
                                style={{ overflowY: 'scroll' }}
                            >

                                <MDBox
                                    height="100%"
                                    display="grid"
                                    gridAutoRows="max-content"
                                    gridTemplateColumns="1fr 1fr 1fr" gap="1.5rem"
                                >
                                    {unmatchedXrays?.map((xray) => (
                                        <XrayThumb
                                            key={xray.xray_id}
                                            appointment={appointment}
                                            xray={xray}
                                            onDragStart={handleDragStart}
                                        />
                                    ))}
                                </MDBox>
                            </MDBox>


                        </MDBox>

                        <MDBox display="grid" gridTemplateRows="3rem 50vh" gap="1rem">
                            <MDBox display="flex" justifyContent="space-between" alignItems='flex-end'>
                                <Typography color={MUIColors.SECONDARY} fontWeight="bold">
                                    X-Rays for Evaluation
                                </Typography>

                                <MDButton
                                    color={MUIColors.SUCCESS}
                                    variant="contained"
                                    onClick={async () => {
                                        const totalMissingXrays = examItems.flatMap((examItem) => {
                                            const matchedXRayViewTypes = Object.values(examItem.matchedXrays).map((xray) => xray.client_provided_view_type)
                                            const requiredViews = Object
                                                .values(examItem.requiredViews)
                                                .filter((reqView) => !reqView.isOptional)
                                                .map((reqView) => reqView.key)

                                            return requiredViews.filter(reqView => {
                                                return !matchedXRayViewTypes.includes(reqView)
                                            });
                                        })

                                        if (totalMissingXrays.length > 0) {
                                            setShowConfirmMissingXraysModal(true)
                                            return
                                        }

                                        handleSubmission()

                                        navigate(routesMap[RouteKeys.TRIAGE_LIST].route)
                                    }}
                                >
                                    Submit X-rays
                                </MDButton>
                            </MDBox>

                            <MDBox
                                border="3px solid"
                                borderColor={colors.secondary.main}
                                borderRadius="10px"
                                padding="1rem"
                                style={{ overflowY: 'scroll' }}
                                display="grid"
                                gridAutoRows="max-content"
                                gap="2rem">
                                {examItems.map((item, i, a) => (
                                    <MDBox key={item.id} display="grid" gap="1rem">
                                        <Typography variant='h4' color={MUIColors.SECONDARY}>{ExamNameNormalizer[item.id]}</Typography>
                                        <MDBox display="grid" gridTemplateColumns="1fr 1fr 1fr" gap="1rem">

                                            {Object.values(item.requiredViews).map((reqView) => {
                                                const viewTypeValue = reqView.key
                                                const xray = item.matchedXrays[viewTypeValue]

                                                return (
                                                    <XrayThumb
                                                        key={`${reqView.key}_${item.id}`}
                                                        appointment={appointment}
                                                        viewType={reqView}
                                                        xray={xray}
                                                        exam={item.id}
                                                        onDrop={handleDrop}
                                                        onDragStart={handleDragStart}
                                                        loading={updateXrayLoading && draggedItem === xray?.xray_id} />
                                                )
                                            })}
                                        </MDBox>
                                    </MDBox>))}

                                <MDBox display="flex" gap="1rem" justifyContent="flex-end">
                                    <MDButton
                                        startIcon={<FontAwesomeIcon icon={faPen} />}
                                        color={MUIColors.SECONDARY}
                                        variant="outlined"
                                        style={{ width: 'fit-content' }}
                                        onClick={async () => {
                                            setShowEditJointsModal(true)
                                        }}>

                                        Edit Joints
                                    </MDButton>
                                </MDBox>

                            </MDBox>
                        </MDBox>
                    </MDBox>
                </MDBox>
            </TriageFlowLayout >

            <Modal open={showEditJointsModal} onClose={() => setShowEditJointsModal(false)}>
                <MDBox {...containerStyles({ justifyContent: 'center', alignItems: 'center' })}>
                    <Card {...containerStyles({
                        width: mdBreakpointBreached ? '90vw' : '50%',
                        height: '30vh',
                        maxWidth: '50rem',
                        minWidth: '20rem',
                        padding: '20%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        position: 'relative',
                    })}>
                        <FontAwesomeIcon
                            icon={faX}
                            cursor='pointer'
                            onClick={() => setShowEditJointsModal(false)}
                            style={{ position: 'absolute', top: '1rem', right: '1rem', color: colors.secondary.main }}
                        />

                        <MDBox {...containerStyles({
                            flexDirection: 'row',
                            height: 'fit-content',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '2.5rem',
                            gap: '2rem'
                        })}>
                            <EditJointsForm appointment={appointment} />
                        </MDBox>
                    </Card>
                </MDBox>
            </Modal>

            <Modal open={showConfirmMissingXraysModal} onClose={() => setShowConfirmMissingXraysModal(false)}>
                <MDBox {...containerStyles({ justifyContent: 'center', alignItems: 'center' })}>
                    <Card {...containerStyles({
                        width: mdBreakpointBreached ? '90vw' : '50%',
                        height: '30vh',
                        maxWidth: '50rem',
                        minWidth: '20rem',
                        padding: '20%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        position: 'relative',
                    })}>
                        <FontAwesomeIcon
                            icon={faX}
                            cursor='pointer'
                            onClick={() => setShowConfirmMissingXraysModal(false)}
                            style={{ position: 'absolute', top: '1rem', right: '1rem', color: colors.secondary.main }}
                        />

                        <MDTypography sx={{ marginBottom: '2rem' }} variant='h3' fontWeight='regular' color={MUIColors.SECONDARY}>
                            WARNING: <span style={{ color: colors.error.focus }}>YOU ARE MISSING X-RAYS</span>
                        </MDTypography>
                        <MDTypography variant='h5' color={MUIColors.SECONDARY} fontWeight="regular">
                            This may result in an inaccurate diagnosis.
                        </MDTypography>
                        <MDTypography variant='h5' color={MUIColors.SECONDARY} fontWeight="regular">
                            Are you sure you want to proceed with the evaluation?
                        </MDTypography>
                        <MDBox {...containerStyles({
                            flexDirection: 'row',
                            height: 'fit-content',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '2.5rem',
                            gap: '2rem'
                        })}>
                            <MDButton
                                color={MUIColors.SECONDARY}
                                variant='contained'
                                onClick={async () => {
                                    handleSubmission()

                                    navigate(routesMap[RouteKeys.TRIAGE_LIST].route)
                                }}>
                                Process X-rays
                            </MDButton>
                            <MDButton variant='contained' color={MUIColors.SECONDARY} onClick={() => navigate(routesMap[RouteKeys.TRIAGE_LIST].route)}>Save for Later</MDButton>
                        </MDBox>
                    </Card>
                </MDBox>
            </Modal>
        </Container>
    );
};

const MatchXraysFallback: React.FC = () => {
    return (
        <Container>
            <MDAppBar hideQuickStartButtons />
            <Grid item xs={12} pt={2}>
                <Card>
                    <MDBox
                        mx={1}
                        mt={-3}
                        py={2}
                        px={3}
                        bgColor={MUIColors.SECONDARY}
                        borderRadius="lg"
                        coloredShadow="secondary"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height={"5rem"}
                        position="relative"
                    >
                        <MDBox
                            position='absolute'
                            top='.8rem'
                            left='1rem'
                        >
                            <MDTypography
                                sx={{ marginBottom: '0' }}
                                variant='h5'
                                color={MUIColors.WHITE}
                            >
                                ----- ------
                            </MDTypography>
                            <MDTypography
                                fontSize='1rem'
                                color={MUIColors.WHITE}
                            >
                                Patient ID: ------
                            </MDTypography>
                        </MDBox>

                        <MDTypography
                            variant="h5"
                            color={MUIColors.WHITE}
                        >
                            Upload X-rays
                        </MDTypography>

                    </MDBox>
                    <MDBox p={2} height="50vh">
                        <MDBox
                            height="50vh"
                            display="grid"
                            justifyContent="center"
                            top='.8rem'
                            left='1rem'
                            padding='3rem'
                            gap='1rem'
                        >
                            <Spinner />
                            <Typography variant="h4" style={{ marginTop: '10px', color: colors.secondary.main }}>Loading...</Typography>
                        </MDBox>
                    </MDBox>
                </Card>
            </Grid >
        </Container >
    )
}

const MatchXraysPage: React.FC = () => {
    const { accessToken, user } = useAuth();
    const {
        appointmentId,
        appointmentDateISOString,
    } = useAppointmentParamsFromQuery();
    const {
        appointment,
        appointmentLoading
    } = useAppointmentData({
        selectedDateISOString: appointmentDateISOString,
        appointmentId,
    });
    const {
        singlePatient,
        singlePatientLoading,
    } = usePatientData({ patientId: appointment?.patient_id });
    const {
        allAppointmentXrays,

    } = useXrayData({ appointment });

    return (!(appointmentLoading || singlePatientLoading || appointmentLoading) &&
        appointment && singlePatient && allAppointmentXrays && appointmentId && user && accessToken

    ) ? <MatchXraysContent
        user={user}
        accessToken={accessToken}
        appointment={appointment}
        singlePatient={singlePatient}
        allAppointmentXrays={allAppointmentXrays}
    /> : <MatchXraysFallback />
}


export default MatchXraysPage;
