import React, { useMemo } from 'react';
import { useActivePatient } from 'hooks/useActivePatient';
import { useAppointmentParamsFromQuery } from 'hooks/useAppointmentParamsFromQuery';
import { useAppointmentData } from 'hooks/useAppointmentData';
import { RouteKeys } from 'models/RouteModels';
import { useNavigation } from 'hooks/useNavigation';
import { useProData } from 'hooks/useProData';
import { useXrayData } from 'hooks/useXrayData';
import { FullJoints } from 'models/XrayModels';
import {
    collectRoiIds,
    containerStyles,
    degenRankings,
    filterAccionPredSectsByFullJoint,
    getThumbnailXray,
    getWorstCaseDegeneration,
    getXraysMappedToActiveJoints,
    isAllActiveJointXraySectionsConfirmed,
    mapClientFavoredPredictionsFromAccionPredSects,
} from 'helpers/helpers';
import { JointOutcomesCardLoader } from 'components/organisms/JointOutcomesCard/JointOutcomesCard';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { Breakpoints } from 'models/StyleModels';
import { useXrayMutations } from 'hooks/useXrayMutations';
import MDTypography from 'components/atoms/MDTypography/MDTypography';
import MDBox from 'components/atoms/MDBox/MDBox';
import colors from 'assets/theme/base/colors';
import { useRoiImageData } from 'hooks/useRoiImageData';
import ErrorPage from '../ErrorPage/ErrorPage';
import { useAuth } from 'hooks/useAuth';
import ActionSlugs from 'services/PermissionsService';
import TriageJointOutcomesSection from 'components/organisms/TriageJointOutcomesSection/TriageJointOutcomesSection';
import MDButton from 'components/atoms/MDButton/MDButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { routesMap } from 'data/routes';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import TriageFlowLayout from 'components/templates/TriageFlowLayout/TriageFlowLayout';
import Container from './TriageExamRoomAllJointPage.styles';

const ExamRoomAllJointsPage: React.FC = () => {
    const { breakpointBreached: lgBreached } = useBreakpoints({ breakpoint: Breakpoints.LARGE });
    const { breakpointBreached: mdBreached } = useBreakpoints({ breakpoint: Breakpoints.MEDIUM });
    const { navigateToBaseRouteWithAptmtDetails } = useNavigation();
    const navigate = useNavigate();
    const { UPDATE$XRAY } = ActionSlugs;

    const { user } = useAuth();
    const { activePatient } = useActivePatient();

    const {
        appointmentId,
        appointmentDateISOString,
    } = useAppointmentParamsFromQuery();

    const {
        appointment,
        appointmentError,
        appointmentLoading,
    } = useAppointmentData({
        selectedDateISOString: appointmentDateISOString,
        appointmentId,
        preventRefetch: true,
    });

    const {
        latestProsMappedToJoints,
        allAppointmentProsLoading,
        allAppointmentProsError,
    } = useProData({ appointment, options: { preventRefetch: true } });

    const {
        xraysMappedToJoints,
        allAppointmentXraysLoading,
        allAppointmentXraysError,
    } = useXrayData({ appointment });

    const xraysMappedToActiveJoints = useMemo(() => getXraysMappedToActiveJoints(appointment, xraysMappedToJoints), [appointment, xraysMappedToJoints]);

    const allActiveJointXRays = useMemo(() => {
        return Object.values(xraysMappedToActiveJoints).flat();
    }, [xraysMappedToActiveJoints]);

    const roiIds = useMemo(() => allActiveJointXRays ? collectRoiIds(allActiveJointXRays) : null, [allActiveJointXRays]);

    const { roiImgUrls, roiImagesLoading } = useRoiImageData({
        roiIds,
        options: { preventRefetch: true },
    });

    const initalQueriesLoading = appointmentLoading || allAppointmentProsLoading || allAppointmentXraysLoading;
    const isError = appointmentError || allAppointmentProsError || allAppointmentXraysError;

    const { batchConfirmXrays, updateXrayLoading } = useXrayMutations({ appointment });

    const allXraysConfirmed = isAllActiveJointXraySectionsConfirmed(appointment, allActiveJointXRays);

    const sortKey: { [key in FullJoints]?: { worstCase: number, proScore: number } } = Object.entries(xraysMappedToActiveJoints)
        .filter(([_, xrays]) => xrays.some(xrays => xrays.prediction_results))
        .reduce((acc, [fullJoint, xRays]) => {
            return {
                ...acc,
                [fullJoint]: {
                    worstCase:
                        degenRankings[
                        getWorstCaseDegeneration(
                            mapClientFavoredPredictionsFromAccionPredSects(
                                filterAccionPredSectsByFullJoint(
                                    xRays.map((xray) => xray.prediction_results.sections).flat() || [],
                                    fullJoint as FullJoints,
                                )
                            ))!],
                    proScore: latestProsMappedToJoints[fullJoint as FullJoints]?.scores?.overall,
                }
            }
        }, {})

    const jointCards = initalQueriesLoading && !!appointment
        ? Object.entries(FullJoints).map(([fj]) => (
            <JointOutcomesCardLoader
                key={fj}
                displayVertical={mdBreached}
            />
        ))
        : Object.entries(sortKey || {})
            .sort(([, aSort], [, bSort]) => {
                if (bSort.worstCase === aSort.worstCase) {
                    if (aSort.proScore && bSort.proScore) {
                        return aSort.proScore - bSort.proScore;
                    }

                    if (aSort.proScore && !bSort.proScore) {
                        return -1
                    }

                    if (!aSort.proScore && bSort.proScore) {
                        return 1
                    }

                    return 0
                }

                return bSort.worstCase - aSort.worstCase;
            })
            .map(([fullJoint]) => (
                <TriageJointOutcomesSection
                    key={fullJoint}
                    appointment={appointment!}
                    hideCTA={!user?.can(UPDATE$XRAY)}
                    roiImgUrls={roiImgUrls}
                    roiImagesLoading={roiImagesLoading}
                    fullJoint={fullJoint as FullJoints}
                    pro={latestProsMappedToJoints[fullJoint as FullJoints]!}
                    xrays={xraysMappedToJoints[fullJoint as FullJoints]!}
                    displayVertical={mdBreached}
                    allXraysConfirmed={
                        !filterAccionPredSectsByFullJoint(
                            xraysMappedToJoints[fullJoint as FullJoints]?.map((xray) => xray.prediction_results.sections).flat() || [],
                            fullJoint as FullJoints,
                        ).find((sectionPrediction) => !sectionPrediction.clientConfirmed)
                    }
                    handleConfirmXrays={() => batchConfirmXrays(xraysMappedToJoints[fullJoint as FullJoints] || [], fullJoint as FullJoints)}
                    confirmRequestLoading={updateXrayLoading}
                    jointDegenOutcomes={
                        mapClientFavoredPredictionsFromAccionPredSects(
                            filterAccionPredSectsByFullJoint(
                                xraysMappedToJoints[fullJoint as FullJoints]?.map((xray) => xray.prediction_results.sections).flat() || [],
                                fullJoint as FullJoints,
                            )
                        )
                    }
                    thumbnailXray={getThumbnailXray(fullJoint as FullJoints, xraysMappedToJoints[fullJoint as FullJoints] || [])}
                />
            ));

    if (!initalQueriesLoading && !appointment) return (
        <ErrorPage code={404} />
    );

    if (isError) return (
        <ErrorPage />
    );

    return (
        <TriageFlowLayout title="Exam Room" singlePatient={activePatient}>
            {!initalQueriesLoading && !jointCards.length && (
                <MDBox {...containerStyles({
                    textAlign: 'center',
                    padding: '5% 10%',
                    paddingBottom: '2rem',
                    width: '100%',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                })}>
                    <MDTypography
                        variant='h3'
                        sx={{
                            color: colors.grey[600],
                            marginBottom: '2rem',
                        }}
                    >
                        Fully analyzed joint outcomes will appear here.
                    </MDTypography>
                    <MDTypography
                        variant='body1'
                        sx={{
                            color: colors.grey[600]
                        }}
                    >
                        Missing a joint?
                        <br />
                        You may still need to provide PROs or required X-rays.
                    </MDTypography>
                </MDBox>
            )}

            <MDBox display="grid" marginBottom="2rem">

                {lgBreached && <MDBox display={lgBreached ? 'grid' : 'flex'} padding='2rem 0' gap="1rem">
                    <MDButton
                        variant="outlined"
                        fullWidth={!lgBreached}
                        style={{
                            left: '0px',
                            color: colors.secondary.main,
                            borderColor: colors.secondary.main
                        }}
                        onClick={() => navigate(routesMap[RouteKeys.TRIAGE_LIST].route)}>
                        <FontAwesomeIcon
                            icon={faChevronLeft}
                            color={colors.secondary.main}
                            style={{ marginRight: '.5rem' }} />
                        Patient List
                    </MDButton>
                    <MDButton
                        fullWidth={lgBreached}
                        variant={allXraysConfirmed ? "contained" : "outlined"}
                        style={{
                            width: lgBreached ? 'auto' : 'fit-content',
                            color: allXraysConfirmed ? colors.white.main : colors.secondary.main,
                            backgroundColor: allXraysConfirmed ? colors.secondary.main : colors.white.main,
                            borderColor: colors.secondary.main
                        }}
                        onClick={() => allXraysConfirmed
                            ? navigateToBaseRouteWithAptmtDetails({
                                routeKey: RouteKeys.TRIAGE_REPORT,
                                appointmentId: appointment!.appointment_id,
                                appointmentDateISOString: appointment!.appointment_date,
                            })
                            : batchConfirmXrays(allActiveJointXRays || [])}>
                        {allXraysConfirmed ? 'View Summary Report' : 'Confirm All Joints'}
                    </MDButton>
                </MDBox>}

                <MDBox
                    {...containerStyles({
                        width: '100%',
                        textAlign: lgBreached ? 'center' : 'left',
                        marginBottom: '2rem',
                        alignItems: 'center',
                        borderRadius: '8px',
                        padding: '.5rem 0',
                        background: `linear-gradient(90deg, rgba(255,255,255,0) 0%, ${colors.disabled.focus} ${mdBreached ? '20%' : '40%'}, ${colors.disabled.focus} 50%, ${colors.disabled.focus} ${mdBreached ? '80%' : '60%'}, rgba(255,255,255,0) 100%)`,
                        position: 'relative',
                        display: 'grid',
                        justifyContent: 'space-around',
                        gridTemplateColumns: 'auto 1fr auto',
                    })}
                >
                    {!lgBreached &&
                        <MDButton
                            variant="outlined"
                            style={{
                                width: 'fit-content',
                                color: colors.secondary.main, borderColor: colors.secondary.main
                            }}
                            onClick={() => navigate(routesMap[RouteKeys.TRIAGE_LIST].route)}>

                            <FontAwesomeIcon
                                icon={faChevronLeft}
                                color={colors.secondary.main}
                                style={{ marginRight: '.5rem' }} />
                            Patient List
                        </MDButton>
                    }

                    <MDTypography
                        textAlign="center"
                        sx={{ color: colors.secondary.main }}
                        variant='h3'
                        fontWeight='regular'>
                        X-RAY ANALYSIS OUTCOMES
                    </MDTypography>

                    {!lgBreached &&
                        <MDButton
                            variant={allXraysConfirmed ? "contained" : "outlined"}
                            style={{
                                width: 'fit-content',
                                color: allXraysConfirmed ? colors.white.main : colors.secondary.main,
                                backgroundColor: allXraysConfirmed ? colors.secondary.main : colors.white.main,
                                borderColor: colors.secondary.main
                            }}
                            onClick={() => allXraysConfirmed
                                ? navigateToBaseRouteWithAptmtDetails({
                                    routeKey: RouteKeys.TRIAGE_REPORT,
                                    appointmentId: appointment!.appointment_id,
                                    appointmentDateISOString: appointment!.appointment_date,
                                })
                                : batchConfirmXrays(allActiveJointXRays || [])}>
                            {allXraysConfirmed ? 'View Summary Report' : 'Confirm All Joints'}
                        </MDButton>
                    }
                </MDBox>
            </MDBox>

            <Container>
                {jointCards}
            </Container>

            {
                !!jointCards.length
                && jointCards.length !== Object.values(appointment?.joints || []).filter((rank) => !!rank).length
                && (
                    <MDTypography
                        variant='body2'
                        sx={{
                            color: colors.grey[600],
                            width: '100%',
                            textAlign: 'center',
                            margin: '2.5rem 0 1.5rem 0',
                        }}
                    >
                        Missing a joint? You may still need to provide a required X-ray.
                    </MDTypography>
                )
            }

        </TriageFlowLayout >
    );
};

export default ExamRoomAllJointsPage;
