import colors from "assets/theme/base/colors";
import MDBox from "components/atoms/MDBox/MDBox";
import MDButton from "components/atoms/MDButton/MDButton";
import MDTypography from "components/atoms/MDTypography/MDTypography";
import { MUIBaseColors, MUIColors } from "models/StyleModels";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { useOrgData } from "hooks/useOrgData";
import { ReactComponent as DownloadIcon } from '../../../assets/images/icons/download.svg';
import border from '../../../assets/images/borders/uploadBoxBorder.png';
import { useXrayMutations } from "hooks/useXrayMutations";
import { useAppointmentParamsFromQuery } from "hooks/useAppointmentParamsFromQuery";
import { useNavigation } from "hooks/useNavigation";
import { RouteKeys } from "models/RouteModels";
import { Card, Container, Grid, Typography } from "@mui/material";
import MDAppBar from "components/organisms/MDAppBar/MDAppBar";
import { useAppointmentData } from "hooks/useAppointmentData";
import { usePatientData } from "hooks/usePatientData";
import Spinner from "components/molecules/Spinner/Spinner";
import { Org } from "models/OrgModels";
import { Patient } from "models/PatientModels";
import TriageFlowLayout from "components/templates/TriageFlowLayout/TriageFlowLayout";
import { Appointment } from "models/AppointmentModels";

const BatchXrayUploadFallback: React.FC = () => {
    return (
        <Container>
            <MDAppBar hideQuickStartButtons />
            <Grid item xs={12} pt={2}>
                <Card>
                    <MDBox
                        mx={1}
                        mt={-3}
                        py={2}
                        px={3}
                        bgColor={MUIColors.SECONDARY}
                        borderRadius="lg"
                        coloredShadow="secondary"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height={"5rem"}
                        position="relative"
                    >

                        <MDBox
                            position='absolute'
                            top='.8rem'
                            left='1rem'
                        >
                            <MDTypography
                                sx={{ marginBottom: '0' }}
                                variant='h5'
                                color={MUIColors.WHITE}
                            >
                                ----- ------
                            </MDTypography>
                            <MDTypography
                                fontSize='1rem'
                                color={MUIColors.WHITE}
                            >
                                Patient ID: ------
                            </MDTypography>
                        </MDBox>

                        <MDTypography
                            variant="h5"
                            color={MUIColors.WHITE}
                        >
                            Upload X-rays
                        </MDTypography>
                    </MDBox>
                    <MDBox p={2}>
                        <MDBox
                            display="grid"
                            justifyContent="center"
                            top='.8rem'
                            left='1rem'
                            padding='3rem'
                            gap='1rem'
                        >
                            <Spinner />
                            <Typography variant="h4" style={{ marginTop: '10px', color: colors.secondary.main }}>Loading...</Typography>
                        </MDBox>
                    </MDBox>
                </Card>
            </Grid >
        </Container>
    )
}

interface IBatchXrayUploadContentProps {
    singlePatient: Patient;
    appointment: Appointment;
    appointmentDateISOString: string;
    org: Org;
}

const BatchXrayUploadContent: React.FC<IBatchXrayUploadContentProps> = ({
    singlePatient,
    appointment,
    appointmentDateISOString
}) => {
    const [acceptedFiles, setAcceptedFiles] = useState<any[]>([]);
    const { navigateToBaseRouteWithAptmtDetails } = useNavigation();
    const {
        createBatchXrays,
        createBatchXrayLoading,
        autoAssignXrays,
        autoAssignXraysLoading,
    } = useXrayMutations({
        appointment
    });

    const {
        getRootProps,
        getInputProps,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/dcm': ['.dcm', '.dicom'],
        },
        onDrop: acceptedFiles => {
            setAcceptedFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        },
        onDropAccepted: async (acceptedFiles) => {
            await createBatchXrays({
                files: acceptedFiles,
                patientId: singlePatient.patient_id
            });

            autoAssignXrays({
                appointmentId: appointment.appointment_id,
                appointmentDate: appointmentDateISOString
            }, {
                onSettled: () => {
                    navigateToBaseRouteWithAptmtDetails({
                        routeKey: RouteKeys.MATCH_XRAYS,
                        appointmentId: appointment.appointment_id,
                        appointmentDateISOString: appointment.appointment_date
                    })
                }
            })
        }
    });

    return (
        <Container>
            <TriageFlowLayout title="Upload X-rays" singlePatient={singlePatient}>

                <div style={{ marginBottom: "6rem", padding: '3rem' }}>
                    <div style={{ marginBottom: "1rem" }}>
                        <span style={{ textAlign: 'center', display: 'block', color: colors.secondary.main, fontWeight: 700, }}>
                            Select all X-rays that are relevant to the patient's chief complaint
                        </span>
                    </div>

                    <MDBox padding='1rem'
                        display="grid"
                        justifyContent="center"
                    >
                        {(createBatchXrayLoading || autoAssignXraysLoading || acceptedFiles.length > 0)
                            ? (
                                <MDBox
                                    display="grid"
                                    justifyContent="center"
                                    top='.8rem'
                                    left='1rem'
                                >
                                    <Spinner />
                                    <Typography variant="h4" style={{ marginTop: '10px', color: colors.secondary.main }}>Processing...</Typography>
                                </MDBox>
                            )
                            : (
                                <MDBox
                                    sx={{
                                        backgroundImage: `url(${border})`,
                                        backgroundSize: "cover",
                                        border: createBatchXrayLoading
                                            ? '3px solid transparent'
                                            : isDragAccept
                                                ? `3px solid ${colors.success.main}`
                                                : isDragReject
                                                    ? `3px solid ${colors.error.main}`
                                                    : 'none',
                                        width: '15rem',
                                        height: '15rem',
                                        aspectRatio: '1 / 1',
                                        borderRadius: '0px',
                                        objectFit: 'cover',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>

                                    <div>
                                        <MDBox
                                            display="grid"
                                            justifyContent="center">

                                            <div>
                                                <MDBox
                                                    {...getRootProps()}
                                                    display="grid"
                                                    justifyContent="center"
                                                    gap="1rem"
                                                    padding="1rem">

                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <DownloadIcon />
                                                    </div>

                                                    <MDButton
                                                        variant='contained'
                                                        size='small'
                                                        color={MUIBaseColors.SECONDARY}
                                                    >
                                                        Browse
                                                    </MDButton>

                                                    <MDTypography textAlign="center" fontSize="10px" sx={{ color: colors.grey[600] }}>
                                                        or drag file to upload
                                                    </MDTypography>

                                                    <MDTypography textAlign="center" fontSize="10px" sx={{ color: colors.grey[600] }}>
                                                        <span style={{ color: 'red' }}>*</span>File types supported: .jpeg, .jpg, .png
                                                    </MDTypography>

                                                </MDBox>
                                            </div>

                                            {!createBatchXrayLoading && <input {...getInputProps()} />}
                                        </MDBox>
                                    </div>
                                </MDBox >)}
                    </MDBox>
                </div>
            </TriageFlowLayout>
        </Container>
    )
}

const BatchXrayUploadPage: React.FC = () => {
    const { org, orgLoading } = useOrgData();
    const {
        appointmentId,
        appointmentDateISOString,
    } = useAppointmentParamsFromQuery();
    const {
        appointment,
        appointmentLoading,
    } = useAppointmentData({
        selectedDateISOString: appointmentDateISOString,
        appointmentId,
        preventRefetch: true,
    });

    const {
        singlePatient,
        singlePatientLoading,
    } = usePatientData({ patientId: appointment?.patient_id });

    return (
        !(singlePatientLoading
            || appointmentLoading
            || orgLoading) &&
        singlePatient && appointment && appointmentDateISOString && org

    ) ? <BatchXrayUploadContent
        singlePatient={singlePatient}
        appointment={appointment}
        appointmentDateISOString={appointmentDateISOString}
        org={org}
    /> : <BatchXrayUploadFallback />
}

export default BatchXrayUploadPage 