import colors from 'assets/theme-dark/base/colors';
import Checkbox from 'components/atoms/Checkbox/Checkbox';
import MDBox from 'components/atoms/MDBox/MDBox';
import MDButton from 'components/atoms/MDButton/MDButton';
import MDTypography from 'components/atoms/MDTypography/MDTypography';
import { useAppointmentMutations } from 'hooks/useAppointmentMutations';
import useForm from 'hooks/useForm';
import { Appointment } from 'models/AppointmentModels';
import { MUIColors } from 'models/StyleModels';
import { FullJoints } from 'models/XrayModels';
import * as React from 'react';

interface IEditJointsFormProps {
    appointment: Appointment;
}

enum FormKeys {
    RIGHT_KNEE = 'RIGHT_KNEE',
    LEFT_KNEE = 'LEFT_KNEE',
    RIGHT_HIP = 'RIGHT_HIP',
    LEFT_HIP = 'LEFT_HIP'
}

type FormValues = {
    [FormKeys.RIGHT_KNEE]: boolean;
    [FormKeys.LEFT_KNEE]: boolean;
    [FormKeys.RIGHT_HIP]: boolean;
    [FormKeys.LEFT_HIP]: boolean;
}

const EditJointsForm: React.FunctionComponent<IEditJointsFormProps> = ({ appointment }) => {
    const {
        updateAppointment,
        updateAppointmentLoading,
        updateAppointmentError,
    } = useAppointmentMutations();

    const {
        values: formValues,
        setFormValue,
    } = useForm<FormValues>({
        [FormKeys.RIGHT_KNEE]: appointment?.joints ? appointment.joints[FullJoints.RIGHT_KNEE] === 1 : false,
        [FormKeys.LEFT_KNEE]: appointment?.joints ? appointment.joints[FullJoints.LEFT_KNEE] === 1 : false,
        [FormKeys.RIGHT_HIP]: appointment?.joints ? appointment.joints[FullJoints.RIGHT_HIP] === 1 : false,
        [FormKeys.LEFT_HIP]: appointment?.joints ? appointment.joints[FullJoints.LEFT_HIP] === 1 : false,
    });

    return <MDBox display="grid" gap="1rem">
        <MDTypography variant='h3' fontWeight='bold' color={MUIColors.SECONDARY}>
            Chief Complaint Selected Joints
        </MDTypography>

        <MDBox display="grid" justifyContent="center">
            <MDBox padding="1rem" border="1px solid #000" borderRadius="3px" width="fit-content" display="grid" gap=".5rem">
                {[
                    FormKeys.RIGHT_KNEE,
                    FormKeys.LEFT_KNEE,
                    FormKeys.RIGHT_HIP,
                    FormKeys.LEFT_HIP,
                ].map(key => (
                    <Checkbox key={key} name={key} checked={!formValues[key]} onChange={() => setFormValue(key, !formValues[key])} />

                ))}
            </MDBox>
        </MDBox>

        <MDBox display="grid" justifyContent="center" gap="1rem">
            <MDButton
                variant='contained'
                color='secondary'
                disabled={updateAppointmentLoading}
                style={{ width: 'fit-content' }}
                onClick={async () => {
                    await updateAppointment({
                        appointmentDate: appointment.appointment_date,
                        appointmentId: appointment.appointment_id,
                        newValues: {
                            joints: {
                                [FullJoints.RIGHT_KNEE]: formValues[FormKeys.RIGHT_KNEE] ? 1 : null,
                                [FullJoints.LEFT_KNEE]: formValues[FormKeys.LEFT_KNEE] ? 1 : null,
                                [FullJoints.RIGHT_HIP]: formValues[FormKeys.RIGHT_HIP] ? 1 : null,
                                [FullJoints.LEFT_HIP]: formValues[FormKeys.LEFT_HIP] ? 1 : null
                            },
                        },
                    });
                }}
            >
                Save & Update
            </MDButton>

            <MDBox>
                {updateAppointmentLoading && <MDTypography style={{ fontSize: '.8rem' }}>Loading...</MDTypography>}
                {updateAppointmentError && <MDTypography style={{ color: colors.error.main, fontSize: '.8rem' }}>Error updating appointment</MDTypography>}
            </MDBox>
        </MDBox>
    </MDBox>
};

export default EditJointsForm;
