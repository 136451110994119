import colors from "assets/theme/base/colors";
import styled from "styled-components";

export const HiddenInput = styled.input`
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    cursor: pointer;
`

export const CheckedIndicator = styled.div<{ checked: boolean }>`
    border: 1px solid black;
    padding: .2rem;
    border-radius: 0.2rem;
`

export const InnerCheckedIndicator = styled.div<{ checked: boolean }>`
    background-color: ${props => props.checked ? 'none' : colors.secondary.main};
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 0.2rem;
`

export const LabelContent = styled.div` 
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    display: relative;
    cursor: pointer;
 `