// React
import { useMemo, useEffect, useState } from "react";

// react-table components
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

// Material Dashboard 2 React components
import MDBox from "components/atoms/MDBox/MDBox";
import MDTypography from "components/atoms/MDTypography/MDTypography";
import MDInput from "components/atoms/MDInput/MDInput";
import MDPagination from "components/atoms/MDPagination/MDPagination";

// Material Dashboard 2 React example components
import DataTableHeadCell from "components/organisms/DataTable/DataTableHeadCell";
import DataTableBodyCell from "components/organisms/DataTable/DataTableBodyCell";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch, faCalendar, faCircleUser } from "@fortawesome/free-solid-svg-icons";
import ErrorRetry from "components/molecules/ErrorRetry/ErrorRetry";
import colors from "assets/theme/base/colors";
import { Breakpoints, MUIColors } from "models/StyleModels";
import MDButton from "components/atoms/MDButton/MDButton";
import { useBreakpoints } from "hooks/useBreakpoints";

export interface TableColumnType {
  Header: string;
  accessor?: string;
  width?: string;
  align?: string;
  isSorted?: boolean;
  canSort?: boolean;
  isSortedDesc?: boolean;
  Cell?: React.ReactNode;
}

export interface TableSecondaryAction {
  label: string;
  action(args?: any): any;
}

export interface TableToggleAction {
  label: string;
  action(args?: any): any;
}

interface DataTableProps {
  entriesPerPage?: {
    defaultValue: number;
    entries: number[];
  };
  canSearch?: boolean;
  showTotalEntries?: boolean;
  table: {
    columns: TableColumnType[];
    rows: { [key: string]: React.ReactNode }[];
  };
  pagination?: {
    variant: 'contained' | 'gradient';
    color: MUIColors | string;
  };
  isSorted?: boolean[];
  noEndBorder?: boolean;
  dataLoading?: boolean;
  dataError?: boolean;
  handleRetry?(): void;
  sx?: { [key: string]: any };
  secondaryAction?: TableSecondaryAction | null,
  handleRowClick?(args: any): any;
  emptyMessage?: string;
  toggle?: TableToggleAction;
}

const DataTable: React.FC<DataTableProps> = ({
  entriesPerPage,
  canSearch,
  showTotalEntries,
  table,
  // pagination,
  isSorted,
  noEndBorder,
  dataLoading,
  dataError,
  handleRetry,
  secondaryAction,
  handleRowClick,
  emptyMessage,
  toggle,
}) => {
  const defaultValue = entriesPerPage?.defaultValue ? entriesPerPage.defaultValue : 10;
  const entries = entriesPerPage?.entries
    ? entriesPerPage.entries.map((el) => el.toString())
    : ["5", "10", "15", "20", "25"];
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);
  const { breakpointBreached } = useBreakpoints({ breakpoint: Breakpoints.LARGE });

  const tableInstance = useTable(
    {
      columns, data, initialState: { pageIndex: 0 },
      autoResetGlobalFilter: false,
      autoResetPage: false
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;

  // Set the default value for the entries per page when component mounts
  // eslint-disable-next-line
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value: number) => setPageSize(value);

  // Render the paginations
  const renderPagination = pageOptions.map((option: number) => (
    <MDPagination
      item
      key={option}
      onClick={() => gotoPage(Number(option))}
      active={pageIndex === option}
    >
      {option + 1}
    </MDPagination>
  ));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option: number) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }: { target: { value: string } }) => gotoPage(Number(parseInt(value.value) - 1));

  // Search input value state
  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value: string) => {
    gotoPage(0);
    setGlobalFilter(value || undefined);
  }, 100);

  // Setting the entries starting point
  const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  // When Hovering Over a Row, Change Cursor and Background Color unless
  // they haven't completed a PRO or recieved Xrays and associated prop has
  // been supplied
  function hoverOnRow(key: number, patient: any) {
    (document.querySelectorAll('.MuiTableRow-root') as unknown as HTMLCollectionOf<HTMLElement>)[key + 1].style.backgroundColor = colors.disabled.focus;
    (document.querySelectorAll('.MuiTableRow-root') as unknown as HTMLCollectionOf<HTMLElement>)[key + 1].style.cursor = handleRowClick ? "pointer" : "default";
  }

  // When Leaving a Row, Change Background Color to Transparent
  function hoverLeaveRow(key: number) {
    (document.querySelectorAll('.MuiTableRow-root') as unknown as HTMLCollectionOf<HTMLElement>)[key + 1].style.backgroundColor = "transparent";
  }

  const fallbackStyles = {
    width: '100%',
    height: '8rem',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& > *': {
      margin: '.5rem 0',
    },
  };

  return (
    <>
      {dataError && <ErrorRetry handleRetry={handleRetry ? handleRetry : () => { }} />}
      {!dataError && (
        <TableContainer sx={{ boxShadow: "none" }}>
          {entriesPerPage || canSearch ? (
            <MDBox display="flex" justifyContent="space-between" alignItems="center" px={3} pb={3}>
              {entriesPerPage && (
                <MDBox display="flex" alignItems="center">
                  <Autocomplete
                    disableClearable
                    value={pageSize.toString()}
                    options={entries}
                    onChange={(event, newValue) => {
                      setEntriesPerPage(parseInt(newValue, 10));
                    }}
                    size="small"
                    sx={{ width: "5rem" }}
                    renderInput={(params) => <MDInput {...params} />}
                  />
                  <MDTypography variant="caption" color={MUIColors.SECONDARY}>
                    &nbsp;&nbsp;entries per page
                  </MDTypography>
                </MDBox>
              )}

              <MDBox
                sx={{
                  width: '100%',
                  display: 'grid',
                  gridTemplateColumns: breakpointBreached ? '1fr' : '2fr 1fr 1fr',
                  gridGap: '1rem',
                }}
              >
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                  {canSearch && (
                    <MDInput
                      placeholder="Patient Search"
                      value={search}
                      size="small"
                      fullWidth
                      onChange={({ currentTarget }) => {
                        setSearch(search);
                        onSearchChange(currentTarget.value);
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon fontSize="medium" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </div>

                {toggle && (
                  <MDBox sx={{
                    whiteSpace: breakpointBreached ? 'normal' : 'nowrap'
                  }}>
                    <MDButton
                      variant='contained'
                      onClick={toggle.action}
                      color={MUIColors.SECONDARY}
                      fullWidth
                    >
                      <FontAwesomeIcon color={colors.white.focus} icon={faCalendar} style={{ marginRight: '10px' }} />
                      {toggle.label}
                    </MDButton>
                  </MDBox>
                )}

                {secondaryAction && (
                  <MDBox
                    sx={{
                      ...(!breakpointBreached ? {
                        position: 'relative',
                        right: '50%'
                      } : null)
                    }}>
                    <MDButton
                      variant="contained"
                      color={MUIColors.SECONDARY}
                      onClick={secondaryAction.action}
                      fullWidth
                    >
                      <FontAwesomeIcon color={colors.white.focus} icon={faCircleUser} style={{ marginRight: '10px', height: '1.2rem' }} />
                      {secondaryAction.label}
                    </MDButton>
                  </MDBox>
                )}

              </MDBox>
            </MDBox>
          ) : null}

          <Table {...getTableProps()}>
            <MDBox component="thead">
              {headerGroups.map((headerGroup: any) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any, index: number) => (
                    <DataTableHeadCell
                      {...column.getHeaderProps(isSorted && isSorted[index] && column.getSortByToggleProps())}
                      width={column.width ? column.width : "auto"}
                      align={column.align ? column.align : "left"}
                    // sorted={setSortedValue(column)}
                    >
                      {column.render("Header")}
                    </DataTableHeadCell>
                  ))}
                </TableRow>
              ))}
            </MDBox>

            {!dataLoading && (
              <TableBody {...getTableBodyProps()}>
                {page.map((row: any, key: number) => {
                  prepareRow(row);
                  return (
                    <TableRow
                      onClick={handleRowClick ? () => handleRowClick(row) : null}
                      onMouseEnter={() => hoverOnRow(key, row)}
                      onMouseLeave={() => hoverLeaveRow(key)}
                      {...row.getRowProps()}
                    >
                      {row.cells.map((cell: any) => (
                        <DataTableBodyCell
                          noBorder={noEndBorder && rows.length - 1 === key}
                          align={cell.column.align ? cell.column.align : "left"}
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </DataTableBodyCell>
                      ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>

          {dataLoading && (
            <MDBox sx={fallbackStyles}>
              <FontAwesomeIcon icon={faCircleNotch} spin color="grey" />
            </MDBox>
          )}

          {!page.length && !dataLoading && (
            <MDBox
              sx={{
                height: '30vh',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <MDTypography textAlign='center' variant='body1' color={MUIColors.DULL}>
                {emptyMessage || 'No data to display.'}
              </MDTypography>
            </MDBox>
          )}

          <MDBox
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            justifyContent="space-between"
            alignItems={{ xs: "flex-start", sm: "center" }}
            p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
          >

            {showTotalEntries && !!rows.length && (
              <MDBox mb={{ xs: 3, sm: 0 }}>
                <MDTypography sx={{ textTransform: 'unset' }} variant="button" color={MUIColors.SECONDARY} fontWeight="regular">
                  Showing {entriesStart} to {entriesEnd < rows.length ? entriesEnd : rows.length} of {rows.length} entries
                </MDTypography>
              </MDBox>
            )}

            {pageOptions.length > 1 && (
              <MDPagination
              // variant={pagination?.variant ? pagination.variant : "gradient"}
              // color={pagination?.color ? pagination.color : "info"}
              >
                {canPreviousPage && (
                  <MDPagination item onClick={() => previousPage()}>
                    <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
                  </MDPagination>
                )}

                {renderPagination.length > 6 ? (
                  <MDBox width="5rem" mx={1}>
                    <MDInput
                      inputProps={{ type: "number", min: 1, max: customizedPageOptions.length }}
                      value={customizedPageOptions[pageIndex]}
                      onChange={handleInputPaginationValue}
                    />
                  </MDBox>
                ) : (
                  renderPagination
                )}

                {canNextPage && (
                  <MDPagination item onClick={() => nextPage()}>
                    <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
                  </MDPagination>
                )}
              </MDPagination>
            )}

          </MDBox>
        </TableContainer >
      )}
    </>
  );
}

export default DataTable;
