import { snakeToTitleCase } from 'helpers/helpers';
import * as React from 'react';
import MDTypography from '../MDTypography/MDTypography';
import { CheckedIndicator, HiddenInput, InnerCheckedIndicator, LabelContent } from './Checkbox.styles';

interface ICheckboxProps {
    name: string
    onChange: () => void;
    checked: boolean;
}

const Checkbox: React.FunctionComponent<ICheckboxProps> = ({ name, onChange, checked }) => {
    return (
        <label key={name}>
            <LabelContent>
                <MDTypography fontWeight={600} fontSize={'1rem'}> {snakeToTitleCase(name)}</MDTypography>
                <HiddenInput
                    type="checkbox"
                    name={name}
                    checked={checked}
                    onChange={onChange}
                />

                <CheckedIndicator checked={checked}>
                    <InnerCheckedIndicator checked={checked} />
                </CheckedIndicator>

            </LabelContent>
        </label>)
};

export default Checkbox;
